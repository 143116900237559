.navbar {
  background: #fafafa;
  padding: 0 1rem;

  .navbar-nav .nav-link {
    @media (min-width: $large) {
      font-size: 1.2rem;
      padding: 1rem 0;
      margin: 0 .75rem;
      color: #000;
    }
  }

  .navbar-nav .nav-link:first-of-type {
    @media (min-width: $large) {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
    }
  }

  .navbar-nav .nav-link.active {
    @media (min-width: $large) {
      border-top: .25rem solid $YellowWalk;
      padding-top: .75rem;
      color: #000;
    }
  }

  .navbar-nav .nav-link:hover {
    @media (min-width: $large) {
      border-top: .25rem solid $YellowWalk;
      padding-top: .75rem;
      color: #000;
    }
  }

  .navbar-toggler {
    margin: 0.5rem 0;
  }

  &>.container {
    justify-content: flex-end;
  }

}

.navbar-nav li {
  line-height: 1.5;
}


.dropdown-menu {
  border-radius: 0;
  border: none;
  border-top: 3px solid $YellowWalk;
  background-color: #fafafa;

  @media (max-width: $small) {
    border-top: none;
  }
}

.dropdown-item:hover {
  background-color: #f3f3f3;
}


/* will want to refactor this code after launch */
.list-group-item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: none;
}