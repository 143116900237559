.cta {
  border: .25rem solid;
  width: 100%;
  text-align: center;
  padding: .75rem;
  margin: 2rem 0;
  display: block;

  &:hover {
    text-decoration: none;
  }

  &__primary {
    border-color: #ddd;
    font-size: 2rem;
    color: #2d2d2d;
  }

  &__primary:hover {
    background: #ddd;
    color: #2d2d2d;
  }

  &__primary-small {
    border-color: #ddd;
    font-size: 1rem;
    color: #2d2d2d;
  }

  &__primary-small:hover {
    background: #ddd;
    color: #2d2d2d;
  }

  &__secondary {
    text-transform: uppercase;
    font-size: 1.375rem;
  }

  &__secondary--green {
    background: #339966;
    border-color: #71b894;
    color: #fff;
  }

  &__secondary--green:hover {
    background: #fff;
    color: #339966;
  }

  &__secondary--white {
    background: transparent;
    border-color: #fff;
    color: #fff;
  }

  &__secondary--white:hover {
    background: #fff;
    color: #000;
    border-color: #fff;
  }

  &__secondary--black {
    background: transparent;
    border-color: #171717;
    color: #171717;
  }

  &__secondary--black:hover {
    background: #171717;
    color: $YellowWalk;
    border-color: #171717;
  }

  &__tertiary {
    border: 1px solid #bababa;

    &-no-margin {
      border: 1px solid #bababa;
      margin: 1rem 0;

      &:hover {
        background-color: #eaeaea;
      }
    }

    &:hover {
      background-color: #eaeaea;

    }
  }
}
