.u-no-padding {
  padding: 0;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 0px;
}
.alert h4 {
  margin-top: 0;
  color: inherit;
}
.alert .alert-link {
  font-weight: bold;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
}
.alert > p + p {
  margin-top: 5px;
}
.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}
.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}
.alert-success {
  background-color: #2ecc71;
  border-color: #29b74e;
  color: #3c763d;
}
.alert-success hr {
  border-top-color: #25a245;
}
.alert-success .alert-link {
  color: #2b542c;
}
.alert-info {
  background-color: #3498db;
  border-color: #23a1c8;
  color: #31708f;
}
.alert-info hr {
  border-top-color: #2090b2;
}
.alert-info .alert-link {
  color: #245269;
}
.alert-warning {
  background-color: #f1c40f;
  border-color: #da8f0d;
  color: #8a6d3b;
}
.alert-warning hr {
  border-top-color: #c27f0b;
}
.alert-warning .alert-link {
  color: #66512c;
}
.alert-danger {
  background-color: #e74c3c;
  border-color: #e42533;
  color: #a94442;
}
.alert-danger hr {
  border-top-color: #d61a28;
}
.alert-danger .alert-link {
  color: #843534;
}

.alert {
  border: none;
  color: white;
}
.alert .alert-link {
  color: white;
  font-weight: bold;
  font-variant: small-caps;
}
.alert-success {
  background-color: #2ecc71;
}
.alert-info {
  background-color: #428bca;
}
.alert-warning {
  background-color: #f1c40f;
}
.alert-danger {
  background-color: #e74c3c;
}