.profile {

  &:hover {
    text-decoration: none;
  }

  &__title--wide--background {
    background-color: #fafafa;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      border-bottom: 200px solid $FoutainRunTeal;
      border-left: 150px solid transparent;
      float: right;
      mix-blend-mode: multiply;
    }
  }

  &__title--background {
    padding: 1rem;
  }

  &__person-photo {
    @media (max-width: $medium) {
      text-align: center;
    }
  }

  &__person-name {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0;

    @media (max-width: $medium) {
      text-align: center;
    }
  }

  &__person-title {
    font-size: 1.3rem;

    @media (max-width: $medium) {
      text-align: center;
    }

  }

  &__person-email {
    margin-bottom: .5rem;

    &--icon {
      background-color: $FoutainRunTeal;
      padding: 1rem;
      margin-bottom: .5rem;
      text-align: center;
    }

    &--email {
      background-color: #fafafa;
      padding: 1rem !important;
      margin-bottom: .5rem;

      &:hover {
        background-color: #ededed;
      }
    }

  }

  &__person-phone {

    &--icon {
      background-color: $FoutainRunTeal;
      padding: 1rem;
      margin-bottom: .5rem;
      text-align: center;
    }

    &--phone {
      background-color: #fafafa;
      padding: 1rem !important;
      margin-bottom: .5rem;

      &:hover {
        background-color: #ededed;
      }
    }

  }

  &__staff {
    background: #f5f5f5;
    padding: 1rem;
    border-left: 7px solid $FoutainRunTeal;
    margin: 1rem 0;

    &:hover {
      background: #ededed;
    }
  }

  &__staff-name {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0;

    @media (max-width: $medium) {
      text-align: center;
    }
  }

  &__staff-title {
    font-size: 1rem;

    @media (max-width: $medium) {
      text-align: center;
    }

  }

  &-admin {
    margin-top: 2rem;
  }

  &-meta {
    border-left: 7px solid $YellowWalk;
    padding: .5rem;
  }

  &-row {
    margin-top: 1rem;
  }

}
