.form {
  &__search {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__search--header {
    width: 30%;
    display: none;

    @media (min-width: $large) {
      display: flex;
    }
  }

  &__search--mobile-nav {
    width: 80%;
    margin-bottom: 1rem;

    @media (min-width: $large) {
      display: none;
    }
  }

  &__search-input {
    border-radius: .125rem;
    border: 1px solid #cecece;
    padding: .5rem .725rem;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, .15);
    width: 100%;
    font-size: .875rem;
  }

  &__search-input--hero {
    padding: .825rem 1.325rem;
  }

  &__search-input::placeholder {
    color: #bbb;
  }

  &__search-submit {
    position: absolute;
    right: .5rem;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      top: .4rem;
    }
  }
}