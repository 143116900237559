.resources {
  padding: 5rem 0 13rem;
  margin-top: 10rem;
  background: url(/assets/images/triangles.png);
  background-position: 0 0;

  &__resource {
    background: #f5f5f5;
    width: 100%;
    margin: 1rem 0;
    padding: 2rem 0;

    @media (min-width: $medium) {
      margin: 1rem;
      height: 100%;
      padding: 2rem 1rem;
    }
  }

  &__resource-title {
    text-align: center;
  }

  &__resource-copy {
    padding: 0 2rem;
  }

  &__resource-circle {
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    margin: auto;
    margin-bottom: 1rem;
    text-align: center;
    color: white;
  }

  &__resource-circle--yellow {
    background: $YellowWalk;
  }

  &__resource-circle--brown {
    background: $BellTowerBrick;
  }

  &__resource-circle--teal {
    background: $FoutainRunTeal;
  }

  a {
    text-decoration: underline;
    font-weight: 600;
  }

  a:hover {
    color: #000;
    text-decoration: none;
  }

  li {
    margin: 0.75rem 0;
  }
}

.center-circle {
  margin-top: 1rem;
}
