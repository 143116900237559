.story {

  &__title--wide--background {
    background-color: #fafafa;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      border-bottom: 200px solid $YellowWalk;
      border-left: 150px solid transparent;
      float: right;
      mix-blend-mode: multiply;
    }
  }

  &__title--background {
    padding: 1rem;
  }

  &__meta {
    float: left;
    background: #fafafa;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: .5rem 1rem;
    border-bottom: 3px solid $YellowWalk;
  }

  &__file {
    border-left: 3px solid $YellowWalk;
    padding: 1rem;
    background: #fafafa;
    margin: .5rem 1.5rem .5rem 0;

    &:hover {
      background-color: #eaeaea;
    }
  }

  &__body {
    clear:both;

    &-image {
      padding: 1rem;

      @media (max-width: $small) {
        float: none !important;
        width: 100%;
      }
    }

    & a:link {
      font-weight: 600;
      text-decoration: underline;
    }

    & a:hover {
      text-decoration: none;
    }

    & a:visited {
      color: $PappysPurple;
    }
  }
}

a:link .story__meta:hover {
  background-color: #eaeaea;
}
