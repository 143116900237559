@import "normalize.css";
@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "base/variables";
@import "base/base";
@import "base/utility";
@import "base/typography";
@import "modules/*";

.dropdown-header {
    font-size: 1em;
    font-weight: bold;
    color: black;
}

.list-group a.dropdown-item {
    padding-left: 30px;
}

