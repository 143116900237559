.about {

  &:hover {
    text-decoration: none;
  }

  &__address {
    padding: 1rem;
    border-left: 7px solid $YellowWalk;
    margin-top: 1rem;

    & a:link {
      font-weight: 600;
    }
  }

  &__staff {
    background: #f5f5f5;
    padding: 1rem;
    border-left: 7px solid $YellowWalk;
    margin: 1rem 0;

    &:hover {
      background: #ededed;
    }
  }

  &__staff-name {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0;

    @media (max-width: $medium) {
      text-align: center;
    }
  }

  &__staff-title {
    font-size: 1rem;

    @media (max-width: $medium) {
      text-align: center;
    }

  }

  &__office {
    list-style-type: none;


    & li {
      background-color: #fafafa;
      border-left: 3px solid $YellowWalk;
      padding: .5rem;
      margin-bottom: 1rem;
      font-weight: bold;

      &:hover {
        background-color: #ededed;
      }
    }

  }

  &__district {
    background-color: #fafafa;
    border-left: 7px solid $YellowWalk;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  &__district-contact {
    background-color: #fafafa;
    border-left: 3px solid #bababa;
    padding: .5rem;
    margin-bottom: .5rem;
  }

  &__district-people {
    background-color: #fafafa;
    border-left: 3px solid #bababa;
    padding: .5rem;
    margin-bottom: .5rem;
  }

}

/* override FormStack styling */
.fsBody, .fsForm {
  padding: 0 !important;
}
