/**
 * Base
 *
 * These are top-level styles for things like the <body>. Properties like
 *  box-sizing, body background/font-family and more.
 */

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

ul li a:link {
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
