/**
 * Typography
 *
 * These are top-level styles for the site typography. Styles for h1-h6
 *  body copy, paragraphs and more.
 */

body {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

blockquote,
li,
p {
  line-height: 1.35;
}

h1 { margin-top: 1rem; }

h2 {
  font-size: 1.9375rem;
  margin-bottom: 0;
}

a {
  color: #000;
}

a:hover {
  color: #000;
}
