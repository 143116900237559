/**
 * Variables
 */
$CoalGrey: #4d4038;
$MoonDustGrey: #baa892;
$EverTrueBlue: #5b6870;
$SlaterSkyBlue: #6e99b4;
$AmeliaSkyBlue: #a3d6d7;
$LandGrantGreen: #085c11;
$RossAdeGreen: #849e2a;
$CeleryBogGreen: #c3be0b;
$SpringFestGreen: #e9e45b;
$OakenBucketBrown: #6b4536;
$BellTowerBrick: #b36012;
$MackeyOrange: #ff9b1a;
$YellowWalk: #ffd100;
$FoutainRunTeal: #29a492;
$PappysPurple: #ad1f65;
$white: #fff;

$small: 576px;
$medium: 768px;
$large: 992px;
$xlarge: 1200px;