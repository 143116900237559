.event {

  &__title--wide--background {
    background-color: #fafafa;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      border-bottom: 200px solid $PappysPurple;
      border-left: 150px solid transparent;
      float: right;
      mix-blend-mode: multiply;
    }
  }

  &__title--background {
    padding: 1rem;
  }

  &__meta {
    float: left;
    background: #fafafa;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: .5rem 1rem;
    border-bottom: 3px solid $PappysPurple;
  }

  &__days {
    clear: both;
  }

  &__body {
    clear:both;
    padding: 1rem;
    border-left: 7px solid $PappysPurple;

    & a:link {
      font-weight: 600;
      text-decoration: underline;
    }

    & a:hover {
      text-decoration: none;
    }

    & a:visited {
      color: $PappysPurple;
    }
  }

  &__resource {
    background: #f5f5f5;
    width: 100%;
    margin: 1rem 0;
    padding: 2rem 0;

    @media (min-width: $medium) {
      margin: 1rem;
      height: 85%;
      padding: 2rem 1rem;
    }
  }

  &__resource-title {
    text-align: center;
  }

  &__resource-copy {
    text-align: center;
  }

  &__resource-circle {
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    margin: auto;
    margin-bottom: 1rem;
    text-align: center;
    color: white;
  }

  &__resource-circle--purple {
    background: $PappysPurple;
  }

  &__resource-circle--orange {
    background: $MackeyOrange;
  }

  &__resource-circle--teal {
    background: $FoutainRunTeal;
  }

  a {
    text-decoration: underline;
    font-weight: 600;
  }

  a:hover {
    color: #000;
    text-decoration: none;
  }

  li {
    margin: 0.75rem 0;
  }
}

.center-circle {
  margin-top: 1rem;
}

.mapFix {
  margin-top: -6px;
}