.label {

  text-decoration: none;

  &__title--wide--background {
    background-color: #fafafa;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      border-bottom: 200px solid $MackeyOrange;
      border-left: 150px solid transparent;
      float: right;
      mix-blend-mode: multiply;
    }
  }

  &__title--background {
    padding: 1rem;
  }

  &:hover {
    text-decoration: none;
  }

  &__list {
    list-style-type: none;
    padding: 0; /* reset padding for the ul */

    &-item {
      background: #f5f5f5;
      padding: 1.5rem;
      border-left: 7px solid $MackeyOrange;
      margin: 2rem 0;

      &:hover {
        background: #ededed;
      }

      & h2 {
        font-weight: 600;
        font-size: 1.5rem;
      }

    }

  }

}
